import 'src/pages/index.scss';
import React, { useEffect } from 'react';
import { useApi, useModal } from 'src/hooks';
import Icon from 'src/components/atoms/icon';
import { PAGES } from 'src/constants';
import SEO from 'src/components/atoms/seo';
import SearchModal from 'src/components/modals/search';
import { navigate } from 'gatsby';
import { pushZipCodeEvent } from 'plugins/custom-plugin-adobe-launch';

const { SHOPPING } = PAGES;

export default () => {
  const { showModal, hideModal } = useModal();
  const { getPlans } = useApi();

  const showSearchModal = () => {
    localStorage.setItem('ZipCodeModal', '');
    showModal({
      innerContent: <SearchModal onSubmit={(data: any) => {

        getPlans(data, () => {
          if(data.zipcode) {
            localStorage.setItem('ZipCodeModal', 'true');
            pushZipCodeEvent({
              event: 'linkClick',
              linkInfo:
                {
                  linkAction: 'enterZip',
                  linkName: 'View Plans',
                  linkType: 'other',
                  linkURL: SHOPPING
                },
              userInfo:
                {
                  zipCode: data.zipcode
                }
            });
          }
          navigate(SHOPPING);
        });
      }
      } />
    });
  };

  useEffect(() => {

    return () => {
      hideModal();
    };
  }, []);

  return (
    <>
      <div className='index--no-plans'>
        <Icon type='noResults'/>
        <div className='index--no-plans--body'>A zip code is needed to find protection plans available in your area.</div>
        <div className='index--no-plans--zipcode' onClick={showSearchModal}> Enter a zip code </div>
      </div>
    </>
  );
};

export const Head = () => <SEO />;
